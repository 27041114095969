import { useCallback, useEffect } from "react";
import clsx from "clsx";
import useIsMobile from "@/common/hook/useIsMobile";
import addNoScroll from "@/utils/addNoScroll";
import useOutsideClick from "@/common/hook/useOutsideClick";
import styles from "./index.module.css";

const DropdownMenu = ({ title, children, isOpen, setOpen, containerRef, className = "button-icon" }) => {
  const isMobile = useIsMobile();
  const ref = useOutsideClick(isOpen && (() => setOpen(false)));

  // REMOVE NO SCROLL CLASS WHEN UNLOAD COMPONENT
  useEffect(() => (isMobile ? () => addNoScroll(false) : undefined), [isMobile]);

  const handleToggle = useCallback(() => {
    setOpen((prev) => {
      if (isMobile) addNoScroll(!prev);
      return !prev;
    });
  }, [isMobile, setOpen]);

  return (
    <div className="relative" ref={ref} onClick={handleToggle} role="presentation">
      <div className={clsx(`inline-flex`, className, isOpen && "border-blue text-blue")}>{title}</div>
      <div
        ref={containerRef}
        className={isOpen ? styles.dropdownContainer : "hidden"}
        onClick={(e) => e.stopPropagation()}
        role="presentation"
      >
        {children}
      </div>
    </div>
  );
};

export default DropdownMenu;
